import React from "react";
function CommingSoon() {
  return (
    <div
      style={{
        textAlign: "center",
        alignItems: "center",
        top: "390px",
        marginTop: "200px",
      }}
    >
      <h1 style={{ fontFamily: "cursive", textDecoration: "underline" }}>
        Comming Soon
      </h1>
    </div>
  );
}

export default CommingSoon;
